import React from 'react'
import logo from "../../../assets/svg/logo.png"
export default function Loading() {
  return (
    <div className="loading-container">
      <img src={logo} alt="Loading" className="loading-image" />
      <div className="loading-text">Loading...</div>
    </div>
  )
}
