import React from "react";
import styled from "styled-components";
// Components
import ProjectBox from "../Elements/ProjectBox";
import FullButton from "../Buttons/FullButton";
// Assets
import ProjectImg1 from "../../assets/img/projects/1.jpg";
import ProjectImg2 from "../../assets/img/projects/2.jpg";
import ProjectImg3 from "../../assets/img/projects/3.jpg";
import ProjectImg4 from "../../assets/img/projects/4.jpg";
import ProjectImg5 from "../../assets/img/projects/5.jpg";
import ProjectImg6 from "../../assets/img/projects/6.jpg";
import AddImage2 from "../../assets/img/add/593451595_193976313_1706x960.jpg";

export default function Projects() {
  return (
    <Wrapper id="projects">
      <div className="whiteBg">
        <div className="container text5">
          <HeaderInfo>
            <h1 className="font40 extraBold">Brands we have worked with</h1>
            <p className="font13">
            We value our reputation highly. These companies have chosen to collaborate with us, <br/> allowing our team to provide them with successful outcomes.
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
            <a href="https://shop.hardrock.com/">
              <ProjectBox
                img={ProjectImg1}
                title="Hard Rock Shop"
              
              />
                </a>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
            <a href="https://philzcoffee.com/">
              <ProjectBox
                img={ProjectImg6}
                title="Philz Coffee"
                
                
              />
               </a>
            </div>
            
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
            <a href="https://www.stgusa.com/">
              <ProjectBox
                img={ProjectImg3}
                title="STG Logistics"
                
              />
              </a>
            </div>
          </div>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
            <a href="https://www.theautomonkey.com/">
              <ProjectBox
                img={ProjectImg4}
                title="Auto Monkey"
              
              />
               </a>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
            <a href="https://www.aviatornation.com/">
              <ProjectBox
                img={ProjectImg5}
                title="Aviator Nation"
                
              />
              </a>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <a href="https://www.gnc.com/">
              <ProjectBox
                img={ProjectImg2}
                title="GNC Live Well"
               
               
              />
              </a>
            </div>
          </div>
          <div className="row flexCenter">
            <div style={{ margin: "50px 0", width: "200px" }}>
          
            </div>
          </div>
        </div>
      </div>
      <div className="lightBg">
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <AddLeftInner>
                <ImgWrapper className="flexCenter">
                  <img className="radius8" src={AddImage2} alt="add" />
                </ImgWrapper>
              </AddLeftInner>
            </AddLeft>
            <AddRight>
              
              <h2 className="font40 extraBold">Our team is unmatched in its expertise and dedication.</h2>
              <p className="font12">
              Our team is made up of experienced professionals with extensive knowledge and expertise in their respective fields, we have experts from Guatemala, Colombia, Chile and Taiwan who are dedicated to providing our clients with the best possible service.
              </p>
              <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0" }}>
                <div style={{ width: "250px" }}>
                <a href="#contact">
                  <FullButton title={<p> <b>Get Started</b></p>}  />
                  </a>
                </div>
                
              </ButtonsRow>
            </AddRight>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
padding: 0 5% 0 0;
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
