
import styled from "styled-components";
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import ContactImg1 from "../../assets/img/contact-1.png";
import calendlyLogo from '../../assets/img/Calendly-Logo.png';
import { useState } from "react";



export default function Contact() {
 


  const [formData, setFormData] = useState({
    fname: "",
    email: "",
    subject: "",
    message: "",
  });

  const [validationErrors, setValidationErrors] = useState({}); // State to store validation errors

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setValidationErrors({ ...validationErrors, [e.target.name]: null }); // Clear error for the changed field
    validateForm(e.target.name, e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission
  
    // Perform validation
    const validationErrors = {};
    if (!formData.fname.match(/^[a-zA-Z\s]+$/)) {
      validationErrors.fname = "Please enter a valid name. Numbers and special characters are not allowed.";
    }
    if (!formData.email.includes("@")) {
      validationErrors.email = "Please enter a valid email.";
    }
    if (formData.subject.trim() === "") {
      validationErrors.subject = "Please enter a subject.";
    }
    if (formData.message.trim() === "") {
      validationErrors.message = "Please enter a message.";
    }
  
    // Check if all validation errors are cleared
    if (Object.keys(validationErrors).length === 0) {
      // Simulate successful submission with a delay (optional)
      Swal.fire({
        title: 'Processing...',
        text: 'Please wait while your form is being submitted.',
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
          setTimeout(() => {
            Swal.hideLoading();
            Swal.fire(
              'Submitted!',
              'Your form has been submitted.',
              'success'
            );
           
  
            // Display success message or perform other actions (optional)
            // **Here, you can use your original Swal code without modification.**
            
            // Clear form data (optional)
            setFormData(prevState => ({
              ...prevState,
              fname: "",
              email: "",
              subject: "",
              message: "",
            }));
          }, 1500); // Simulate processing delay (adjust as needed)
        }
      });
    } else {
      // Display validation errors
      Swal.fire({
        title: 'Oops!',
        text: 'Please fix the following errors:',
        icon: 'error',
        html: Object.keys(validationErrors).map(key =>
          `<li>${validationErrors[key]}</li>`
        ).join('')
      });
    }
  };
  

  const validateForm = (name, value) => {
    const errors = {};
    if (name === "fname" && !value.match(/^[a-zA-Z\s]+$/)) {
      errors.fname = "Please enter a valid name. Numbers and special characters are not allowed.";
    }

    if (name === "email" && !value.includes("@") && !value.includes(".com")) {
      errors.email = "Please enter a valid email.";
    }

    setValidationErrors(errors); // Update validation errors state
};

  // const validateForm = (name, value) => {
  //   const errors = {};
  //   if (name === "fname" && !value.match(/^[a-zA-Z]+$/)) {
  //     errors.fname = "Please enter a valid name. Numbers and special characters are not allowed.";
  //   }

  //   if (name === "email" && !value.includes("@")) {
  //     errors.email = "Please enter a valid email.";
  //   }

  //   setValidationErrors(errors); // Update validation errors state

  //   if (Object.keys(errors).length > 0) {
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'Oops...',
  //       text: errors[name], // Display specific error message
  //     });
  //   }
  // };
  return (
    <Wrapper id="contact">
      <div className="lightBg">
        <div className="container">
          <HeaderInfo>
          <h1 className="font40 extraBold">Let's get in touch</h1>
        <p className="font13">
          Our team will reach out to you to analyze the impact and key variables of the project.
          <br />
          We are committed to finding the solutions that best fit your needs.
        </p>

          </HeaderInfo>
          <div className="row" style={{ paddingBottom: "30px" }}>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">

              <Form onSubmit={handleSubmit}>
              {validationErrors.fname && (
              <div className="validation-feedback">
                <FontAwesomeIcon icon={faTimesCircle} className="error-icon" />
                <p className="error-message">{validationErrors.fname}</p>
              </div>
            )}
             {formData.fname && !validationErrors.fname && (
              <div className="validation-feedback">
                <FontAwesomeIcon icon={faCheckCircle} className="success-icon" />
              </div>
            )}
                <label className="font13">First name:</label>
                <input type="text" id="fname" name="fname" onChange={handleChange}  value={formData.fname}  className="font20 extraBold" />
                {validationErrors.email && (
              <div className="validation-feedback">
                <FontAwesomeIcon icon={faTimesCircle} className="error-icon" />
                <p className="error-message">{validationErrors.email}</p>
              </div>
            )}
            {formData.email && !validationErrors.email && (
              <div className="validation-feedback">
                <FontAwesomeIcon icon={faCheckCircle} className="success-icon" />
              </div>
            )}
                <label className="font13">Email:</label>
                <input type="text" id="email" name="email" onChange={handleChange}  value={formData.email}  className="font20 extraBold" />
              
                <label className="font13">Subject:</label>
                <input type="text" id="subject" name="subject" onChange={handleChange} value={formData.subject}  className="font20 extraBold" />
                <label className="font13">Message:</label>
                <textarea rows="4" cols="50" type="text" id="message" onChange={handleChange}   value={formData.message} name="message" className="font20 extraBold" />
                
           
           
                <SumbitWrapper className="flex">
                <ButtonInput type="submit" value={"Send Message"} id="sendButton" className="radius8" style={{  maxWidth: "250px", fontWeight: "bold"  }} />
              </SumbitWrapper>
              
              </Form>

              <div>
          
              <p><b> Create a meet with us on</b></p>
              <a href="https://calendly.com/americansketcher/meet?" target="_blank" rel="noopener noreferrer">
              
          <img src={calendlyLogo} alt="Schedule on Calendly" width="80px" />
         
        </a>
        
        </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex">
              <div style={{ width: "50%" }} className="flexNullCenter flexColumn">
               
                  <img src={ContactImg1} id="imgContact" alt="office" className="radius6" />
                  
                
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
   height: auto;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  
 
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  
  :hover {
    outline: 1px solid #868686;
    color: #fff;

    
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;

const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;









