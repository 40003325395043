import React, { useEffect, useState } from "react";

// Sections

import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
import Projects from "../components/Sections/Projects";
import Footer from "../components/Sections/Footer";
import Sidebar from "../components/Nav/Sidebar";
import TopNavbar from "../components/Nav/TopNavbar";
import Contact from "../components/Sections/Contact";
import Loading from "../components/Sections/pages/Loading";


export default function Landing() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000); // Change this to the amount of time it takes to load your styles

    return () => clearTimeout(timer); // This is to clear the timer if the component unmounts before the timer finishes
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <>  
     <TopNavbar />
    <Sidebar />
     
      <Header />
      <Services />
      <Projects />
      
     
      <Contact />
      <Footer />
    </>
  );
}


